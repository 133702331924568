// @flow
import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Grid, Row} from "react-flexbox-grid";
import "../../Components/Technology/Technology.scss";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

/**
 * @class ProvenScene
 */
class ProvenScene extends React.Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showContentSupportedGUI: false,
      showContentGUI: false,
      showContentCommand: false,
      supportedCommand: false,
    };
  }

  /**
   * Final Render
   * @returns {*}
   */
  render() {

    return (
      <Grid className={"page"}>
        <Helmet>
          <title>Proven - IQRF</title>
        </Helmet>
        <Row className="page__section--bottom-padding">
          <Col xs={12}>
            <h1>Proven</h1>
            <h2>Smarter wireless since 2004</h2>
            <h3>Tradition</h3>
            <ul>
              <li>MICRORISC (IQRF inventor/developer/manufacturer) <strong>established in 1991</strong>.</li>
              <li>It was the first Czech manufacturer of car alarms with floating codes.</li>
              <li>Dealing with <strong>RF since 1996</strong>.</li>
              <li><strong>IQRF introduced in 2004</strong> at the RFM Conference in Malaga, Spain.</li>
              <li><strong>IQRF Tech</strong>, a MICRORISC's <strong>spin-off</strong> company established
                in <strong>2017</strong> to be a successor for <strong>all IQRF related activities</strong>.
              </li>
              <li>IQRF presented at the <strong>Electronica</strong> trade fair in Munich, Germany in 2006, 2008, 2010,
                2012, 2014, 2016, 2018, and 2022.
              </li>
            </ul>
            <h3>Preconditions</h3>
            <ul>
              <li>Almost <Link to="/patents">40 patents</Link> and utility models in wireless, networking and
                automotive (EU, US, JP, CN, CZ).
              </li>
              <li>Manufacturer’s technology centre opened on May 2013.</li>
              <li>
                <a href={"http://www.iqrfalliance.org/"} target="_blank" rel="noreferrer noopener">IQRF
                  Alliance</a>  is an international non-profit association connecting innovative entities from commercial and non-commercial fields to bring solutions to smart buildings, cities, industry, and agriculture. It helps promote IQRF wireless technology and defines the IQRF interoperability standard. It includes design houses, manufacturers, cloud providers, telco operators, system integrators, research and innovation centers, technical high schools, and universities from various countries. The members build up an ecosystem of IQRF interoperable devices and related gateways, software, clouds, mobile apps, and integration platforms.<br/>
                <a href={"http://www.iqrfalliance.org/products"} target="_blank" rel="noreferrer noopener">
                  » Products
                </a>
              </li>
              <li>IQRF research and development utilize cooperation and feedback provided by universities and
                academic entities.
                See <a href={"https://www.iqrfalliance.org/alliance#institutions"} rel="noreferrer noopener"
                       target="_blank">Institutions</a> and <a href={"https://www.iqrfalliance.org/smart-school/"}
                                                               rel="noreferrer noopener"
                                                               target="_blank">IQRF Smart Schools</a> project.
              </li>
            </ul>
            <h3>Interoperability standard</h3>
            <p>
              <Link to="/technology/dpa">DPA</Link> enables worldwide interoperability of products with IQRF inside.
              Certification is possible according to
              the <a href={"http://www.iqrfalliance.org/techDocs/"} rel="noreferrer noopener" target="_blank">IQRF
              Interoperability Standard</a>.
            </p>
            <h3>Awards</h3>
            <ul>
              <li>In <strong>1997, MICRORISC</strong> won the prestigious <strong>Golden Amper</strong> award for the Best Exhibit at the <a href={"http://www.amper.cz/en.html"} target="_blank"><strong>AMPER</strong></a>, an international trade fair of electrotechnics and electronics, for its innovative product <strong>IQALARM</strong> - a car alarm protected by a <strong>rolling code</strong>, ensuring a higher level of security</li>
              <li>In <strong>2010</strong>, another <strong>Golden Amper</strong> award, for <strong>IQRF wireless technology</strong> (among 705 companies from 21 countries).</li>
              <li>
                <strong>Best Paper awards</strong> from <a href={"http://www.iaria.org/"} target="_blank"
                                                           rel="noreferrer noopener">IARIA</a> (International
                Academy, Research and Industry Association) at International Conferences on Advances in Mesh
                Networks <a href={"http://www.iaria.org/conferences2010/MESH10.html"} rel="noreferrer noopener"
                            target="_blank">MESH
                2010</a>, <a href={"http://www.iaria.org/conferences2011/MESH11.html"} rel="noreferrer noopener"
                             target="_blank">MESH
                2011</a>, <a href={"http://www.iaria.org/conferences2012/AwardsICN12.html"}
                             rel="noreferrer noopener"
                             target="_blank">ICN 2012</a> and <a
                href={"http://www.iaria.org/conferences2012/AwardsICNS12.html"} rel="noreferrer noopener"
                target="_blank">ICNS 2012</a>.
              </li>
              <li>MICRORISC awarded 3rd place in Czech Republic as Company of the year 2012</li>
              <li>
                <strong>Embedded award</strong> at <a href={"https://www.embedded-world.de/en"}
                                                      target="_blank" rel="noreferrer noopener">Embedded World</a> 2014
              </li>
              <li>
                <a href={"http://energetab.pl/competition-of-the-energetab-2019-results/?lang=en"}
                   target="_blank" rel="noreferrer noopener">Award</a> at <a
                href={"http://energetab.pl/?lang=en"} rel="noreferrer noopener" target="_blank">Energetab
                trade show</a> 2019
              </li>
              <li>
                <a href={"https://microrisc.com/en/news/iqrf-news/iqrf-awarded-at-the-light-2020-trade-show"}
                   target="_blank" rel="noreferrer noopener">Award</a> at Trade <a
                href={"https://lightfair.pl/language/en/"} rel="noreferrer noopener" target="_blank">Show LIGHT</a> 2020
              </li>
              <li>
                <strong>IQRF Tech</strong> won the <a href={"https://microrisc.com/en/news/iqrf-novinky/iqrf-tech-won-the-golden-amper-award-2023"}
                   target="_blank" rel="noreferrer noopener">Golden Amper award</a> for the best exhibit (IQRF wireless technology) of the international trade fair of Electrotechnics, Energetics, Automation, Communication, Lighting, and Security technologies <a
                href={"https://www.amper.cz/en.html"} rel="noreferrer noopener" target="_blank">AMPER</a>, Brno (CZ), <strong>2023</strong> (400 companies from 24 countries).<br/>&nbsp;
              </li>
              <li>
                <strong>In 2014, IQRF was awarded the most prestigious Czech scientific award <a
                  href={"http://www.ceskahlava.cz/cz/2014/vitezove-ceska-hlava/"} target="_blank"
                  rel="noreferrer noopener">Ceska hlava</a>, the prize "Cena industrie" for the best
                  innovative technology.</strong>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }
}

/**
 * Exporting part of the React.Component file
 */
export default withTranslation()(ProvenScene);